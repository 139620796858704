.accordion :global .MuiAccordionDetails-root > div > div:first-child,
.accordion :global .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

.accordion {
  border: 0;
  height: 100%;
}

.accordion :global .MuiAccordionSummary-root {
  background: transparent !important;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-border-light);
  pointer-events: none;
}

.accordion :global .MuiAccordionSummary-content {
  margin: 0;
}

.sidebar {
  max-height: calc(100vh - var(--header-height) - var(--footer-height) - 8px);
  overflow-y: auto;
}

.content {
  background: linear-gradient(-90deg, #15166D, #a0b3f8);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  border-radius: 6px;
  padding: var(--space-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.createAddCard {
  display: flex;
  flex-direction: column;
  padding: var(--space-4);
  height: 100%;
}

.fileIcon {
  display: flex;
  align-items: center;
  padding: var(--space-1);
  background-color: var(--color-background-main);
}

.card {
  padding: var(--space-3);
  gap: var(--space-2);
  display: flex;
  flex-direction: column;
}

.card :global .MuiCardHeader-root,
.card :global .MuiCardContent-root {
  padding: 0;
}

.cardHeader {
  text-align: left;
}

@media (max-width: 899.95px) {
  .sidebar :global .MuiPaper-root {
    height: 100%;
  }

  .accordion {
    height: auto;
  }

  .accordion :global .MuiAccordionSummary-root {
    pointer-events: auto;
  }

  .accordion :global .MuiAccordionSummary-expandIconWrapper {
    display: block;
  }

  .content {
    padding: var(--space-6);
  }
}

@media (max-width: 599.95px) {
  .content {
    padding: var(--space-4);
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
